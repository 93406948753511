import ThemeProvider from "@material-ui/styles/ThemeProvider"

import * as React from "react"
import theme from "../theme"
import Layout from "../components/layout"
import SEO from "../components/seo"


export default function ThankYou() {
  return  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Credit instant" />
        <h1>Stai linistit! Banii sunt in drum spre tine</h1>
      </Layout>
    </ThemeProvider>
  </React.Fragment>

}